p {
    color: #55433b;
    font-family: Inter;
}
.scroll_container{
    max-height: 100vh; /* Set the max height as per your layout */
    overflow-y: scroll;
}
.container {
    width: calc(100% - 2vw -19vw);
    margin: 0px 2vw 0 19vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container2 {
    position: relative;
    margin: 0px 2vw 0 21vw;
    padding-bottom: 10vh;
}

.img_container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(33% - 2vw); 
    gap: 2vw;
    z-index: 1;
    padding-left: 2vw;
}

.text_container {
    display: flex;
    flex-direction: column;
    width: calc(65% - 1vw);
    gap: 1vw;
    z-index: 1;
    position: relative;
    font-size: 1.2dvw;
    font-weight: 300;
}

.title{
    font-size: 2dvw;
    font-weight: 600;
    margin-bottom: 1vh;
}
.subtitle {
    font-size: 1.2dvw;
    font-weight: 600;
    letter-spacing: 0;
    margin-bottom: 0;
}
.text{
    font-size: 1.2dvw;
    font-weight: 300;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 800px){
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: calc(100% -2vw -19vw);
        margin: 0px 2vw 0 19vw;
        
    }
    .img_container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: calc(100% - 10vw); 
        z-index: 1;
        padding: 2vh 7vw 2vh 3vw;
    }
    .text_container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 1vw); 
        z-index: 1;
        position: relative;
        font-size: 2.2dvw;
        font-weight: 300;
    }

    .title{
        font-size: 3dvw;
    }

    .subtitle {
        font-size: 2.2dvw;
    }
    .text{
        font-size: 2.2dvw;
    }
}