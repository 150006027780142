.arrow {
    padding: 33px 0 0 3.5vw;
}

p {
    font-family: Inter;
}
.parent {
    background-color: #FFD26B ;
}
.meta_block_mobile {
    display: none;
}
.title {
    position: relative;
    margin: 5vw 0 2vw 3.5vw;
    font-size: 2dvw;
    font-weight: 600;
    color: #55433b;
}

.metadata {
    position: relative;
    margin: 0 0 0 3.5vw;
    width: 15vw;
}

.bold {
    color: #55433b;
    font-size: 1.2dvw;
    font-weight: 600;
    letter-spacing: 0;
}

.textblock2 .bold {
    margin-top: 0;
}

.light {
    color: #55433b;
    font-size: 1.2dvw;
    font-weight: 300;
    letter-spacing: 0;
}
.figure_text {
    color: #55433b;
    font-size: 1.2dvw;
    font-weight: 300;
    letter-spacing: 0;
    margin: -6% 0 0 0;
    padding-bottom: 5%;
}
.gridparent {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 0px;
}

.headerimg {
    z-index: 1;
    padding: 0;
    width: 100%;
}
.headerimg img{
    width: 100%;
}

.textblock{
    position: relative;
    margin: 0 calc(70px + 5%) 0 25%;
}

.textblock2{
    padding: 0 1% 0 1%;
    margin-top: 0;
}
.imgblock2{
    padding-bottom: 5%;
    width: 60%;
    height: auto;
}
.flex_container {
    display: flex;
    align-items:start;
}
.imgblock{
    position: relative;
    padding-bottom: 5%;
    width: 100%;
}

.videoblock {
    position: relative;
    padding-bottom: 5%;
    padding-left: 4vw;
    width: 50%;
}

.gif_esigns_1 {
    position: absolute;
    z-index: 3;
    left: 70px;
    top: 107vw;
    width: 30vw;
}

.gif_esigns_2 {
    position: absolute;
    z-index: 3;
    left: calc(70px + 29vw);
    top: 106vw;
    width: 40vw;
}

.gif_ulkus {
    position: absolute;
    z-index: 3;
    left: 70px;
    top: 153vw;
    width: 30vw;
}

.gif_energy {
    position: absolute;
    z-index: 3;
    left: calc(70px + 63vw);
    top: 126vw;
    width: 30vw;
}

.gif_wave_4 {
    position: absolute;
    z-index: 3;
    left: calc(70px + 57vw);
    top: 131vw;
    width: 35vw;
}

.gif_wave_1 {
    position: absolute;
    z-index: 3;
    left: 70px;
    top: 96vw;
    width: 20vw;
}

.gif_wave_2 {
    position: absolute;
    z-index: 3;
    left: calc(70px + 71vw);
    top: 96vw;
    width: 20vw;
}

.gif_form_1 {
    position: absolute;
    z-index: 3;
    left: calc(70px + 65vw);
    top: 98vw;
    width: 30vw;
}

.gif_form_2 {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 105vw;
    width: 25vw;
}

.video_form {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 106vw;
}

@media screen and (max-width: 800px){
    .arrow {
        display: none;
    }
    .gridparent {
        display:inline-block;
    }
    .headerimg {
        z-index: 1;
        padding: 0;
        width: 100%;
        position: relative;
    }
    .headerimg img{
        width: 100%;
    }
    .meta_block {
        display: none;
    }
    .flex_container {
        display: block;
    }
    .textblock2{
        position: relative;
        margin: 0;
        padding: 2% 2% 4% 2%;
    }
    .textblock {
        margin: 0;
        padding: 2% 2% 4% 2%; 
    }
    .imgblock2{
        position: relative;
        padding-bottom: 0;
        width: 100%;
    }
    .title {
        font-size: 3dvw;
    }
    .bold {
        font-size: 2.2dvw;
    }
    .light {
        font-size: 2.2dvw;
    }
    .figure_text {
        font-size: 2.2dvw;
    }
    .gif_ulkus {
        position: absolute;
        z-index: 3;
        left: 20px;
        top: 266vw;
        width: 30vw;
    }
    .gif_esigns_1 {
        position: absolute;
        z-index: 3;
        left: 20px;
        top: 209vw;
        width: 30vw;
    }
    
    .gif_esigns_2 {
        position: absolute;
        z-index: 3;
        left: calc(20px + 29vw);
        top: 209vw;
        width: 40vw;
    }
    .meta_block_mobile {
        display:flex;
    }
    .metadata {
        width: 100%;
    }
    
}