.navbar {
    padding-left: 1.5vw;
    margin-top: 2vw;
}


.mains {
    width: 17vw;
    position: absolute;
    font-family: Inter;
    color: #55433b;
    }

.mains .textwrapper {
    font-size: 1.5dvw;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    padding-bottom: 3vh;
    cursor: pointer;
    }

.mains .name {
        font-size: 2.5dvw;
        font-weight: 200;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        padding-bottom: 8vh;
        margin-top: -1.5vh;
        }
.active {
        text-decoration: underline;
        }

@media screen and (max-width: 800px){
    .mains .textwrapper {
        font-size: 2.6dvw;
        padding-bottom: 2vh;
        }
    
    .mains .name {
            font-size: 3.4dvw;
            margin-top: 0vh;
            padding-bottom: 6vh;
            }
        }