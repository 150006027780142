svg{
    position: absolute; /* or fixed */
    z-index: 0;
    top: 0;
    left: 0;
}

.bg {
  background-color: #FFD26B ;
}

.path {
    stroke-dasharray: 3100;
    stroke-dashoffset: 3100;
   animation: dash 3.5s linear 1 forwards;
  }
  
   @keyframes dash {
    from {
      stroke-dashoffset: 3100;
    }
    to {
      stroke-dashoffset: 0;
    }
  } 