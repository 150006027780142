.scroll_container{
    max-height: 100vh; /* Set the max height as per your layout */
    overflow-y: scroll;
}
p {
    font-family: Inter;
}
.parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 2vw -19vw);
    margin: 0px 2vw 0 19vw;
    
}
.column{
    display: flex;
    flex-direction: column;
    width: calc(50% - 1vw); /* 2 columns with some space between */
    gap: 1vw;
    z-index: 1;
    padding-bottom: 5%;
}
.column2{
    display: flex;
    flex-direction: column;
    width: calc(50% - 1vw); /* 2 columns with some space between */
    gap: 1vw;
    z-index: 1;
    padding-bottom: 2%;
}

.card {
    border-radius: 0px;
    box-shadow: 5px 8px 5px #00000044;  /*#F38AF7 #00D1E7*/
    margin-bottom: 2%;
}
.card img {
    height: auto;
    width: 100%;
    border-radius: 0px 0px 0 0;
    margin: 0px 0 3% 0;   
}
.video_card {
    height: auto;
    width: 100%;
    margin: 0px 0 5px 0;  
    box-shadow: 5px 8px 5px #00000044;
    background-color: #000000;  
}
.title {
    color: #55433b;
    font-size: 1.5dvw;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0 0 0 0;
}

.title2 {
    font-size: 2dvw;
    font-weight: 600;
    color: #55433b;
}
.subtitle2 {
    color: #55433b;
    font-size: 1.2dvw;
    font-weight: 600;
    letter-spacing: 0;
}
.text {
    color: #55433b;
    font-size: 1.2dvw;
    font-weight: 300;
    letter-spacing: 0;
}
.header {
    color: #55433b;
    font-size: 1.5dvw;
    font-weight: 400;
    letter-spacing: 0;
    margin: 0 0 1vw 19vw;
    position: relative;
}

.subtitle {
    color: #55433b;
    font-size: 1.5dvw;
    font-weight: 200;
    letter-spacing: 0;
    margin: 0% 0 3% 0;
}
.text_block {
    height: 100%;
    padding: 0 0 5vh 1vw;
}
.text_block_mobile {
    display: none;
}
.card p {
    padding: 0 0 0 3%;
}
.group1 {
    opacity: 0;
    animation: fadeInAnimation 0.8s ease 1 forwards 0.5s;
}

.group2 {
    opacity: 0;
    animation: fadeInAnimation 0.8s ease 1 forwards 1.2s;
}
.group3 {
    opacity: 0;
    animation: fadeInAnimation 0.8s ease 1 forwards 1.6s;
}
.group4 {
    opacity: 0;
    animation: fadeInAnimation 0.8s ease 1 forwards 2s;
}


@keyframes fadeInAnimation {
    0% {
        opacity: 0;
        scale: 0.7;
    }

    100% {
        opacity: 1;
        scale: 1;
    }
}

@media screen and (max-width: 800px){
    .parent {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: calc(100% -2vw -19vw);
        margin: 0px 2vw 0 19vw;
        
    }
    .column{
        display: flex;
        flex-direction: column;
        width: calc(100% - 1vw);
        z-index: 1;
        padding-bottom: 5%;
    }
    .column2{
        display: flex;
        flex-direction: column;
        width: calc(100% - 1vw); 
        z-index: 1;
        padding-bottom: 2%;
    }
    .text_block {
        display: none;
    }
    .text_block_mobile {
        display: block;
    }

    .title {
        font-size: 2.5dvw;
        font-weight: 600;
        letter-spacing: 0;
        margin: 0 0 0 0;
    }
    
    .title2 {
        font-size: 3dvw;
        font-weight: 600;
    }
    .subtitle2 {
        font-size: 2.2dvw;
        font-weight: 600;
        letter-spacing: 0;
    }
    .text {
        font-size: 2.2dvw;
        font-weight: 300;
        letter-spacing: 0;
    }
    .header {
        font-size: 2.5dvw;
        font-weight: 400;
        letter-spacing: 0;
        margin: 0 0 1vw 19vw;
        position: relative;
    }
    
    .subtitle {
        font-size: 2.5dvw;
        font-weight: 200;
        letter-spacing: 0;
        margin: 0% 0 3% 0;
    }
}